import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shibani Joshi starts her interview with Arlyn Davich, PayPerks CEO with this:`}</p>
    <blockquote>
      <p parentName="blockquote">{`I got to catch up with one of the hottest newcomers…PayPerks: a company you’re going to be hearing a lot more about in the future…`}</p>
    </blockquote>
    <p><inlineCode parentName="p">{`#youhearditherefirstfolks`}</inlineCode></p>
    <p>{`Arlyn does a great job explaining what PayPerks is and what we do. Have a watch:`}</p>
    <iframe src="//www.youtube.com/embed/tr6d_1w0Lhw?rel=0" height="315" width="560" allowFullScreen="" frameBorder="0"></iframe>
    <p>{`Link to the YouTube video directly: `}<a parentName="p" {...{
        "href": "http://www.youtube.com/watch?v=tr6d_1w0Lhw"
      }}>{`http://www.youtube.com/watch?v=tr6d_1w0Lhw`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      